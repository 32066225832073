// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects {
  display: flex;
  flex-direction: column;
}

.project {
  display: grid;
  grid-gap: 0 1.2em;
  grid-template-areas: "title image";
  width: 70%;
  padding: 2em 0;
}
.project h1 {
  font-size: 2em;
}
.project img {
  grid-area: image;
  height: 128px;
}
.project.project-even {
  text-align: right;
  align-self: flex-end;
}
.project.project-even img {
  border-radius: 129px 27px 13px 86px;
}
.project.project-even .box-shadow-pop {
  animation-name: box-shadow-pop;
}
.project.project-odd {
  grid-template-areas: "image title";
}
.project.project-odd img {
  border-radius: 27px 129px 86px 13px;
}
.project.project-odd .box-shadow-pop {
  animation-name: box-shadow-pop-left;
}
.project:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .project {
    grid-gap: 0 0;
    width: 100%;
  }
  .project img {
    height: 84px;
  }
  .project.project-even, .project.project-odd {
    grid-template-areas: "image image" "title title";
    text-align: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/projects/projects.component.sass"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;AACD;;AACA;EACC,aAAA;EACA,iBAAA;EACA,kCAAA;EACA,UAAA;EACA,cAAA;AAED;AAAC;EACC,cAAA;AAEF;AAAC;EACC,gBAAA;EACA,aAAA;AAEF;AAAC;EACC,iBAAA;EACA,oBAAA;AAEF;AAAE;EACC,mCAAA;AAEH;AAAE;EACC,8BAAA;AAEH;AAAC;EACC,kCAAA;AAEF;AAAE;EACC,mCAAA;AAEH;AAAE;EACC,mCAAA;AAEH;AAAC;EACC,gBAAA;AAEF;;AACA;EAEC;IACC,aAAA;IACA,WAAA;EACA;EACA;IACC,YAAA;EACD;EACA;IAEC,gDAAA;IACA,gBAAA;EAAD;AACF","sourcesContent":[".projects\n\tdisplay: flex\n\tflex-direction: column\n\n.project\n\tdisplay: grid\n\tgrid-gap: 0 1.2em\n\tgrid-template-areas: 'title image'\n\twidth: 70%\n\tpadding: 2em 0\n\n\th1\n\t\tfont-size: 2em\n\n\timg\n\t\tgrid-area: image\n\t\theight: 128px\n\n\t&.project-even\n\t\ttext-align: right\n\t\talign-self: flex-end\n\n\t\timg\n\t\t\tborder-radius: 129px 27px 13px 86px\n\n\t\t.box-shadow-pop\n\t\t\tanimation-name: box-shadow-pop\n\n\t&.project-odd\n\t\tgrid-template-areas: 'image title'\n\n\t\timg\n\t\t\tborder-radius: 27px 129px 86px 13px\n\n\t\t.box-shadow-pop\n\t\t\tanimation-name: box-shadow-pop-left\n\n\t&:last-child\n\t\tmargin-bottom: 0\n\n\n@media screen and (max-width: 768px)\n\n\t.project\n\t\tgrid-gap: 0 0\n\t\twidth: 100%\n\n\t\timg\n\t\t\theight: 84px\n\n\t\t&.project-even,\n\t\t&.project-odd\n\t\t\tgrid-template-areas: 'image image' 'title title'\n\t\t\ttext-align: left\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
