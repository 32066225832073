// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlights-list {
  position: relative;
  list-style: none;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.highlight-wrapper {
  display: flex;
  position: relative;
  width: 260px;
  height: 260px;
  padding: 0.6em 1.2em;
  border: 1px solid black;
  background: #ffffff;
  box-shadow: 0.9em 0.9em 0 -6px #f89d33;
  font-size: 1.3em;
}
.highlight-wrapper:first-child {
  top: 50px;
}
.highlight-wrapper:nth-child(2) {
  top: 10px;
}
.highlight-wrapper:nth-child(3) {
  top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/highlights/highlights.component.sass"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;EACA,UAAA;AACD;;AACA;EACC,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sCAAA;EACA,gBAAA;AAED;AACC;EACC,SAAA;AACF;AACC;EACC,SAAA;AACF;AAEC;EACC,SAAA;AAAF","sourcesContent":[".highlights-list\n\tposition: relative\n\tlist-style: none\n\theight: 100%\n\tdisplay: flex\n\tjustify-content: space-between\n\tmargin: 0\n\tpadding: 0\n\n.highlight-wrapper\n\tdisplay: flex\n\tposition: relative\n\twidth: 260px\n\theight: 260px\n\tpadding: 0.6em 1.2em\n\tborder: 1px solid black\n\tbackground: #ffffff\n\tbox-shadow: 0.9em 0.9em 0 -6px #f89d33\n\tfont-size: 1.3em\n\n\n\t&:first-child\n\t\ttop: 50px\n\n\t&:nth-child(2)\n\t\ttop: 10px\n\n\n\t&:nth-child(3)\n\t\ttop: 20px\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
