// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill {
  background: #fff;
  width: 160px;
  height: 160px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 300ms ease-out, transform 300ms ease-out;
}
.skill:hover {
  box-shadow: 0.9em 0.9em 0 -0.3em #f89d33;
  transform: scale(1.1);
}
.skill img {
  max-width: 120px;
  max-height: 64px;
}

.skills-component {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 3em;
  align-items: center;
  justify-items: center;
}

.skills-title {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  align-items: center;
  justify-items: center;
}

@media screen and (max-width: 1120px) {
  .skills-component {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .skills-component {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 420px) {
  .skill {
    background: #fff;
    width: 120px;
    height: 120px;
  }
  .skill img {
    max-width: 96px;
    max-height: 64px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/skills/skills.component.sass"],"names":[],"mappings":"AAEA;EACC,gBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,+DAAA;AADD;AAGC;EACC,wCAAA;EACA,qBAAA;AADF;AAGC;EACC,gBAAA;EACA,gBAAA;AADF;;AAGA;EACC,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;AAAD;;AAEA;EACC,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;AACD;;AACA;EACC;IACC,qCAAA;IACA,uBAAA;EAEA;AACF;AAAA;EACC;IACC,qCAAA;EAEA;AACF;AAAA;EACC;IACC,gBAAA;IACA,YAAA;IACA,aAAA;EAEA;EAAA;IACC,eAAA;IACA,gBAAA;EAED;AACF","sourcesContent":["@import \"../../../_partials/variables\"\n\n.skill\n\tbackground: #fff\n\twidth: 160px\n\theight: 160px\n\tborder-radius: 2px\n\tdisplay: flex\n\tjustify-content: center\n\tflex-direction: column\n\talign-items: center\n\ttransition: box-shadow 300ms ease-out, transform 300ms ease-out\n\n\t&:hover\n\t\tbox-shadow: 0.9em 0.9em 0 -0.3em $secondary-color\n\t\ttransform: scale(1.1)\n\n\timg\n\t\tmax-width: 120px\n\t\tmax-height: 64px\n\n.skills-component\n\tdisplay: grid\n\tgrid-template-columns: repeat(5, 1fr)\n\tgrid-template-rows: repeat(4, 1fr)\n\tgrid-column-gap: 1em\n\tgrid-row-gap: 3em\n\talign-items: center\n\tjustify-items: center\n\n.skills-title\n\tgrid-column-start: 1\n\tgrid-column-end: 3\n\tgrid-row-start: 1\n\tgrid-row-end: 3\n\talign-items: center\n\tjustify-items: center\n\n@media screen and (max-width: 1120px)\n\t.skills-component\n\t\tgrid-template-columns: repeat(3, 1fr)\n\t\tgrid-template-rows: 1fr\n\n\n@media screen and (max-width: 768px)\n\t.skills-component\n\t\tgrid-template-columns: repeat(2, 1fr)\n\n\n@media screen and (max-width: 420px)\n\t.skill\n\t\tbackground: #fff\n\t\twidth: 120px\n\t\theight: 120px\n\n\t\timg\n\t\t\tmax-width: 96px\n\t\t\tmax-height: 64px\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
