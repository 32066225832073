// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-sending {
  opacity: 0.3;
}
.is-sending:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: transparent;
}

.contact-form-container {
  transition: opacity 0.3s ease;
}

.form-status:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 150px;
  height: 2px;
  margin-top: 10px;
  background: #00adb5;
  animation: scaleX 300ms ease;
}
.form-status-error:after {
  background: #f89d33;
}`, "",{"version":3,"sources":["webpack://./src/app/components/contact-form/contact-form.component.sass"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;AACC;EACC,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,uBAAA;AACF;;AAEA;EACC,6BAAA;AACD;;AAEC;EACC,WAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,4BAAA;AACF;AAEE;EACC,mBAAA;AAAH","sourcesContent":[".is-sending\n\topacity: 0.3\n\n\t&:before\n\t\tcontent: ''\n\t\twidth: 100%\n\t\theight: 100%\n\t\tposition: absolute\n\t\tz-index: 10\n\t\tbackground: transparent\n\n\n.contact-form-container\n\ttransition: opacity 0.3s ease\n\n.form-status\n\t&:after\n\t\tcontent: ''\n\t\tdisplay: block\n\t\twidth: 100%\n\t\tmax-width: 150px\n\t\theight: 2px\n\t\tmargin-top: 10px\n\t\tbackground: #00adb5\n\t\tanimation: scaleX 300ms ease\n\n\t&-error\n\t\t&:after\n\t\t\tbackground: #f89d33\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
