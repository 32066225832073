// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  position: relative;
  scroll-snap-align: start;
  min-height: 100vh;
}

.section-project-summary {
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0;
  padding-bottom: 0;
}
.section-project-summary .screenshots {
  position: relative;
}
.section-project-summary .screenshots img {
  opacity: 0;
}
.section-project-summary .screenshots img.laptop {
  width: 590px;
  z-index: 1;
  animation-duration: 1.8s, 3s;
  animation-delay: 0.3s, 2.1s;
}
.section-project-summary .screenshots img.mobile {
  position: absolute;
  width: 240px;
  top: 56px;
  z-index: 2;
  right: 0;
}
.section-project-summary .summary {
  width: 100%;
  margin-left: 4em;
  margin-bottom: 3em;
}

@media screen and (max-width: 1180px) {
  .section-project-summary .screenshots img.laptop {
    width: 460px;
  }
  .section-project-summary .screenshots img.mobile {
    position: absolute;
    width: 200px;
    top: 40px;
    right: 30px;
  }
}
@media screen and (max-width: 900px) {
  .section-project-summary {
    flex-wrap: wrap;
  }
  .section-project-summary .screenshots {
    margin: 3em 0 1em;
  }
  .section-project-summary .screenshots img.laptop {
    max-width: 460px;
    width: 100%;
  }
  .section-project-summary .screenshots img.mobile {
    width: 35%;
    top: 8%;
    right: 10%;
  }
  .section-project-summary .summary {
    margin-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/project/project.component.sass"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,wBAAA;EACA,iBAAA;AACD;;AACA;EACC,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAED;AAAC;EACC,kBAAA;AAEF;AAAE;EACC,UAAA;AAEH;AAAG;EACC,YAAA;EACA,UAAA;EACA,4BAAA;EACA,2BAAA;AAEJ;AAAG;EACC,kBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;AAEJ;AAAC;EACC,WAAA;EACA,gBAAA;EACA,kBAAA;AAEF;;AAAA;EAKG;IACC,YAAA;EADF;EAGC;IACC,kBAAA;IACA,YAAA;IACA,SAAA;IACA,WAAA;EADF;AACF;AAEA;EACC;IACC,eAAA;EAAA;EAEA;IACC,iBAAA;EAAD;EAEC;IACC,gBAAA;IACA,WAAA;EAAF;EAEC;IACC,UAAA;IACA,OAAA;IACA,UAAA;EAAF;EAEA;IACC,cAAA;EAAD;AACF","sourcesContent":[".section\n\tposition: relative\n\tscroll-snap-align: start\n\tmin-height: 100vh\n\n.section-project-summary\n\talign-items: center\n\tflex-wrap: nowrap\n\tmargin-bottom: 0\n\tpadding-bottom: 0\n\n\t.screenshots\n\t\tposition: relative\n\n\t\timg\n\t\t\topacity: 0\n\n\t\t\t&.laptop\n\t\t\t\twidth: 590px\n\t\t\t\tz-index: 1\n\t\t\t\tanimation-duration: 1.8s, 3s\n\t\t\t\tanimation-delay: .3s, 2.1s\n\n\t\t\t&.mobile\n\t\t\t\tposition: absolute\n\t\t\t\twidth: 240px\n\t\t\t\ttop: 56px\n\t\t\t\tz-index: 2\n\t\t\t\tright: 0\n\n\t.summary\n\t\twidth: 100%\n\t\tmargin-left: 4em\n\t\tmargin-bottom: 3em\n\n@media screen and (max-width: 1180px)\n\t.section-project-summary\n\n\t\t.screenshots\n\n\t\t\timg.laptop\n\t\t\t\twidth: 460px\n\n\t\t\timg.mobile\n\t\t\t\tposition: absolute\n\t\t\t\twidth: 200px\n\t\t\t\ttop: 40px\n\t\t\t\tright: 30px\n\n@media screen and (max-width: 900px)\n\t.section-project-summary\n\t\tflex-wrap: wrap\n\n\t\t.screenshots\n\t\t\tmargin: 3em 0 1em\n\n\t\t\timg.laptop\n\t\t\t\tmax-width: 460px\n\t\t\t\twidth: 100%\n\n\t\t\timg.mobile\n\t\t\t\twidth: 35%\n\t\t\t\ttop: 8%\n\t\t\t\tright: 10%\n\n\t\t.summary\n\t\t\tmargin-left: 0\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
