// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-image img,
.hero-image svg,
.hero-image object {
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/page-not-found/page-not-found.component.sass"],"names":[],"mappings":"AACC;;;EAGC,oBAAA;AAAF","sourcesContent":[".hero-image\n\timg,\n\tsvg,\n\tobject\n\t\talign-self: flex-end\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
